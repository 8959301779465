import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import firebaseConfig from '../../firebase.config.json'

// firebase init
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection indices
const indicesCollection = db.collection('indices')

// collection users
const usersCollection = db.collection('users')

// export utils/refs
export { db, auth, indicesCollection, usersCollection }
