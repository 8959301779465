<template>
  <Fragment>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Stock Analysis
          </v-list-item-title>
          <v-list-item-subtitle
            v-html="
              'Welcome' +
                (isAuthenticated ? `, ${userProfile.fullname}` : '') +
                '!'
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Index Stocks</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/watchlist">
          <v-list-item-icon>
            <v-icon>mdi-trending-up</v-icon>
          </v-list-item-icon>
          <v-list-item-title>My Watchlists</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        v-if="isAuthenticated"
        @click="drawer = true"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" text to="/" class="hidden-sm-and-down">
        Index Stocks
      </v-btn>
      <v-btn
        v-if="isAuthenticated"
        text
        to="/watchlist"
        class="ml-2 hidden-sm-and-down"
      >
        My Watchlists
      </v-btn>
      <v-btn icon @click="toggleDark" class="ml-2">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
      <v-btn v-if="isAuthenticated" color="error" icon @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
      <v-btn v-else color="success" icon @click="login">
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
    </v-app-bar>
  </Fragment>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import { Fragment } from 'vue-fragment'

export default {
  name: 'AppNav',
  components: { Fragment },
  data() {
    return {
      appTitle: 'Stock Analysis',
      drawer: false,
    }
  },
  computed: {
    ...mapState(['userProfile']),
    isAuthenticated() {
      return Object.keys(this.userProfile).length > 1
    },
  },
  methods: {
    toggleDark: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
    },
    logout: function() {
      this.$store.dispatch('logout')
    },
    login: function() {
      if (router.currentRoute.name !== 'Login') router.push('login')
    },
  },
  mounted() {
    const theme = localStorage.getItem('dark_theme')
    if (theme) {
      if (theme == 'true') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    }
  },
}
</script>

<style>
.v-app-bar {
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}
</style>
